import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { map } from 'rxjs';
import { SfoUiJSONSchema7 } from '../../../metadata.model';
import { SchemaKeywords, SfoUiWidgetType } from '../../types';

@Component({
  selector: 'sfo-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
  standalone: false,
})
export class DynamicInputComponent implements OnInit {
  @Input() schema!: SfoUiJSONSchema7;
  @Input() aFormControl!: FormControl;
  @Input() defaultValue: unknown;
  @ViewChild(MatRipple) ripple: MatRipple;

  private userEvent: boolean = false;

  SchemaKeywords = SchemaKeywords;
  SfoUiWidgetType = SfoUiWidgetType;

  ngOnInit(): void {
    this.aFormControl.valueChanges
      .pipe(
        map(() => {
          if (!this.userEvent) {
            this.ripple?.launch({
              centered: true,
              animation: { enterDuration: 500, exitDuration: 0 },
            });
          }
        }),
      )
      .subscribe();

    this.initValueForZeroMinLength();
  }

  onFocus() {
    this.userEvent = true;
  }

  onBlur() {
    this.userEvent = false;
  }

  resetToDefault() {
    if (this.schema && this.aFormControl) {
      this.aFormControl.setValue(this.defaultValue || this.schema.default);
    }
  }

  private initValueForZeroMinLength() {
    if (this.schema?.minLength === 0 && this.aFormControl.value === null) {
      this.aFormControl.setValue(this.schema.default || this.defaultValue, { emitEvent: false });
    }
  }
}
