import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { JSONSchema7 } from 'json-schema';
import { BehaviorSubject } from 'rxjs';
import { SfoUiJSONSchema7 } from '../metadata.model';
import { SchemaKeywords, SfoUiWidgetType } from './types';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private _advancedMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly advancedMode$ = this._advancedMode$.asObservable();

  constructor(private formBuilder: FormBuilder) {}

  /**
   * Toggles the advanced mode between true and false.
   */
  toggleAdvancedMode(): void {
    this._advancedMode$.next(!this._advancedMode$.value);
  }

  generateForm(schema: SfoUiJSONSchema7): FormGroup {
    if (!schema) {
      throw new Error('No schema provided');
    }

    if (!schema['properties']) {
      throw new Error('Invalid schema provided. Properties are missing');
    }

    try {
      return this.buildFormGroup(schema);
    } catch (e) {
      throw e;
    }
  }

  buildFormGroup(schema: SfoUiJSONSchema7, prefix = '', defaults?: unknown): FormGroup {
    const baseForm: FormGroup = this.formBuilder.group({});

    const properties = schema['properties'] || schema;

    for (const key in properties) {
      const property = properties[key];
      const fullKey = prefix ? `${prefix}.${key}` : key; // get total key path

      if (!property.type) {
        if (property['anyOf'] || property['oneOf']) {
          baseForm.addControl(key, this.buildUnionFormControl(property, fullKey));
        }
      } else if (Array.isArray(property.type)) {
        // TODO: Remove this when ready - Backwards compatibility for ["string", "number"]
        baseForm.addControl(key, this.buildUnionArrayFormControl(property.type, fullKey));
      } else {
        switch (property.type) {
          case 'array': {
            if (!property.items) {
              console.error(
                `The property ${fullKey} is an array type and must have some items.\nProperty:\n${property}`,
              );
              break;
            }

            if (
              property?.[SchemaKeywords.UiWidget] === SfoUiWidgetType.EnumInput &&
              property?.[SchemaKeywords.UiWidget]
            ) {
              baseForm.addControl(key, this.formBuilder.control(undefined));
            } else {
              baseForm.addControl(
                key,
                this.buildFormArray(
                  property,
                  fullKey,
                  defaults?.[key] || // access default value of an object, passed down from root
                    defaults || // access default value of primitive, passed from root
                    property?.default || // access default at root level
                    undefined,
                ),
              );
            }
            break;
          }
          case 'object': {
            if (key === 'options') debugger;

            const newFormControl = this.buildFormGroup(
              property,
              fullKey,
              defaults?.[key] || // access default value of an object, passed down from root
                defaults || // access default value of primitive, passed from root
                property?.default || // access default at root level
                undefined,
            );

            baseForm.addControl(key, newFormControl);
            break;
          }
          case 'number': {
            let validationRules = this.buildNumberValidators(property);
            baseForm.addControl(key, this.formBuilder.control(undefined, validationRules));
            break;
          }
          case 'string': {
            let validationRules = this.buildStringValidators(property);
            baseForm.addControl(key, this.formBuilder.control(undefined, validationRules));
            break;
          }
          case 'boolean': {
            baseForm.addControl(key, this.formBuilder.control(undefined));
            break;
          }
          default:
            throw new Error(
              `Building form failed. Path "${fullKey}" has an unsupported type "${property.type}".`,
            );
        }
      }
    }
    return baseForm;
  }

  buildFormControl(schema: any): FormControl {
    const validators: ValidatorFn[] = this.buildStringValidators(schema);
    return new FormControl('', validators);
  }

  /**
   * Finds a schema property at the given path in the schema hierarchy
   * @param schema - The root schema object to search in
   * @param path - Dot-notation path to the desired property (e.g. "data.altTitle")
   * @returns The schema property if found, null otherwise
   */
  findPropertySchema(schema: any, path: string): SfoUiJSONSchema7 | null {
    const paths = path.split('.');
    let current = schema;

    if (current.properties) {
      for (const part of paths) {
        if (current.properties && current.properties[part]) {
          current = current.properties[part];
        } else if (current.items && part === 'items') {
          current = current.items;
        } else {
          return null;
        }
      }
    }

    return current;
  }

  /**
   * Gets the full path of a control in the form hierarchy
   * @param form - The form group containing the control
   * @param key - The control key to find the path for
   * @returns The full dot-notation path to the control
   */
  getControlPath(form: FormGroup, key: string): string {
    let path = key;
    let parent = form;

    while (parent && parent.parent instanceof FormGroup) {
      const parentKey = Object.keys(parent.parent.controls).find(
        (k) => parent?.parent?.get(k) === parent,
      );
      if (parentKey) {
        path = `${parentKey}.${path}`;
        parent = parent.parent;
      } else {
        break;
      }
    }

    return path;
  }

  private buildStringValidators(property: any): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (property.minLength) {
      validators.push(Validators.minLength(property.minLength));
    }

    if (property.maxLength) {
      validators.push(Validators.maxLength(property.maxLength));
    }

    if (property.pattern) {
      validators.push(Validators.pattern(property.pattern));
    }

    if (property.format) {
      switch (property.format) {
        case 'email':
          validators.push(Validators.email);
          break;
      }
    }

    return validators;
  }

  private buildNumberValidators(property: JSONSchema7): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (property.minimum) {
      validators.push(Validators.min(property.minimum));
    }

    if (property.maximum) {
      validators.push(Validators.max(property.maximum));
    }

    return validators;
  }

  public buildFormArray(property: any, fullKey: string, schemaDefaults?: any): FormArray {
    const formArray = this.formBuilder.array<FormGroup | FormControl>([]);

    if (property.items.type === 'object') {
      // If there are default properties that live on the root level of the schema object
      if (Array.isArray(property.default) && property.default?.length) {
        property.default.forEach((defaultValue: any) => {
          // must parse the default levels down when building form group
          formArray.push(this.buildFormGroup(property.items, fullKey, defaultValue));
        });
        // collect defaults parsed from the root level
      } else if (Array.isArray(schemaDefaults) && schemaDefaults.length) {
        // build form with the defaults living on the root level
        schemaDefaults.forEach((defaultValue: any) => {
          formArray.push(this.buildFormGroup(property.items, fullKey, defaultValue));
        });
      } else {
        formArray.push(this.buildFormGroup(property.items, fullKey));
      }
    } else {
      const defaults = Array.isArray(property.default) ? property.default : [];
      defaults.forEach((defaultValue: any) => {
        formArray.push(this.formBuilder.control(defaultValue));
      });
    }

    return formArray;
  }

  /**
   * Builds a form control for properties with anyOf or oneOf definitions in the schema.
   * This handles complex unions like:
   * - Array combined with null: an array that can also be null
   * - Simple type unions: string, number, or null in any combination
   *
   * @param property - The schema property containing anyOf or oneOf
   * @param key - The property key for error messages
   * @returns A FormControl for simple types or FormArray for array types
   * @throws Error if the union type combination is not supported
   */
  private buildUnionFormControl(property, key): FormControl | FormArray {
    const typeSet = property.anyOf || property.oneOf;
    if (!typeSet) {
      throw new Error(`Property "${key}" must have anyOf or oneOf defined`);
    }

    const types = typeSet.map((option) => option.type);

    if (types.includes('array')) {
      const defaultToNull = property.examples?.find((ex) => ex.value === null);
      if (defaultToNull) {
        return this.formBuilder.control(null);
      }

      const arraySchemaType = typeSet.find((option) => option.type === 'array');
      if (!arraySchemaType || !arraySchemaType.items) {
        return this.formBuilder.array([]);
      }

      return this.buildFormArray(arraySchemaType, key, property.default);
    }

    const validSimpleTypes = ['string', 'number', 'null'];
    const isSimpleTypeUnion = types.every((type) => validSimpleTypes.includes(type));

    if (isSimpleTypeUnion) {
      return this.formBuilder.control<string | number | null | undefined>(undefined);
    }

    throw new Error(
      `Unsupported anyOf/oneOf types for property: "${key}". Currently supporting only simple types (string/number/null) or array combinations.`,
    );
  }

  /**
   * Builds a form control for properties with an array type definition.
   * Simple type unions like ["string", "number"] are generated.
   * https://github.com/YousefED/typescript-json-schema/blob/master/test/programs/type-union/main.ts
   *
   * @param property - The array of types from the schema
   * @param key - The property key for error messages
   * @returns A FormControl configured for the union type
   * @throws Error if the array contains unsupported types
   * @deprecated Use anyOf/oneOf pattern instead of array type definition
   */
  private buildUnionArrayFormControl(property: string[], key: string): FormControl {
    const validTypes = ['string', 'number'];

    const targetArray = [...property].sort();
    const sourceArray = [...validTypes].sort();

    let isSame = true;

    for (let i = 0; i < targetArray.length; i++) {
      if (sourceArray[i] !== targetArray[i]) isSame = false;
    }

    if (!isSame || property.length !== validTypes.length) {
      throw new Error(`Property must have ${validTypes}. Contains ${property} for ${key}`);
    }

    const unionControl = this.formBuilder.control('');

    return unionControl;
  }
}
