<div [ngClass]="{'lg-col-8 md-col-10 col-12' : renderMode !== 'quickSettings'}">
  @if (aFormControl || !schema?.[SchemaKeywords.UiHide]) {
    @switch (schema?.type) {
      @case ('object') {
        <ng-template
          *ngTemplateOutlet="
            objectTemplate;
            context: {
              $implicit: {
                aFormGroup: aFormControl?.get(control),
                schema: schema?.properties,
                controlName: control,
                depth: 0
              }
            }
            "
        ></ng-template>
      }

      @case ('array') {
        <ng-template
          *ngTemplateOutlet="
            arrayTemplate;
            context: {
              $implicit: {
                aFormArray: aFormControl?.get(control),
                schema: schema,
                controlName: control,
                title: schema?.['title'],
                description: schema?.['description']
              }
            }
          "
        ></ng-template>
      }

      @default {
        <ng-template
          *ngTemplateOutlet="
            primitiveTemplate;
            context: {
              $implicit: {
                aFormControl: aFormControl?.get(control),
                schema: schema,
                controlName: control
              }
            }
            "
        ></ng-template>
      }
    }
  }
</div>

<!-- Object Template -->
<ng-template #objectTemplate let-context>
  @for (prop of (context.schema | unsortedKeyvalue); track prop.key) {
    @switch (prop.value['type']) {
      @case ('object') {
        <div class="mt2 col-12">
          <!-- Quick Settings -->
          @if (renderMode === 'quickSettings') {
            <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
          }
          <!-- Settings View -->
          @if (renderMode !== 'quickSettings') {
            <!-- If root property of object -->
            @if (context.depth === 0) {
              <!-- Check if the properties has more than one property -->
              @if (prop.value.properties | hasMultipleKeys) {
                <!-- First level node with more than one property - construct panel -->
                <div class="pb2">
                  <mat-expansion-panel class="mat-elevation-z0" togglePosition="after">
                    <mat-expansion-panel-header class="mat-panel-header">
                      <mat-panel-title class="mat-panel-title">
                        {{prop?.value?.title ?? prop?.key | titlecase}}
                      </mat-panel-title>

                      @if (prop.value.description) {
                        <mat-panel-description
                          class="mat-panel-description"
                          [matTooltip]="prop.value.description"
                        >
                          {{ prop.value.description }}
                        </mat-panel-description>
                      }
                    </mat-expansion-panel-header>

                    <div class="pt2 mat-panel-body">
                      <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
                    </div>
                  </mat-expansion-panel>
                </div>
              } @else {
                <!-- Content to show if there's no panel, or simply nothing -->
                <div class="m0">
                  <h2 class="my2 h2">
                    {{prop?.value?.title ?? prop?.key | titlecase}}
                  </h2>
                  <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
                </div>
              }
            } @else {
              <div class="m0 pl2 pt2">
                <h3 class="m0" [ngClass]="{'h4': context.depth === 1, 'h5': context.depth === 2}">
                  {{prop?.value?.title ?? prop?.key | titlecase}}
                </h3>

                @if (prop.value.description) {
                  <p class="small" *ngIf="prop.value?.['description']">
                    {{ prop.value["description"] }}
                  </p>
                }
              </div>
              <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
            }
          }
          <ng-template #dynamicField>
            <ng-template
              *ngTemplateOutlet="objectTemplate;
              context: {
                $implicit: {
                  aFormGroup: context.aFormGroup?.get(prop.key),
                  schema: prop.value?.['properties'],
                  depth: context.depth + 1,
                  default: context?.default?.[prop.key] || context?.default || '',
                  controlName: prop.key
                },
              }"
            ></ng-template>
          </ng-template>
        </div>
      }

      @case ('array') {
        @if (context.depth === 0 && renderMode !== 'quickSettings') {
          <div class="mt2 col-12">
            <mat-expansion-panel class="mat-elevation-z0" togglePosition="after">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ prop?.value?.title || prop?.key | titlecase }}
                </mat-panel-title>

                @if (prop.value.description) {
                  <mat-panel-description
                    class="mat-panel-description"
                    [matTooltip]="prop.value.description"
                  >
                    {{ prop.value.description }}
                  </mat-panel-description>
                }
              </mat-expansion-panel-header>
              <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
            </mat-expansion-panel>
          </div>
        } @else {
          <ng-template *ngTemplateOutlet="dynamicField"></ng-template>
        }
        <ng-template #dynamicField>
          <ng-template
            *ngTemplateOutlet="arrayTemplate;
            context: {
              $implicit: {
                aFormArray: context.aFormGroup?.get(prop.key),
                schema: prop.value,
                controlName: prop.key,
                title: prop.value?.['title'],
                description: prop.value?.['description']
              }
            }"
          ></ng-template>
        </ng-template>
      }

      @default {
        <ng-template
          *ngTemplateOutlet="
              primitiveTemplate;
              context: {
                $implicit: {
                  aFormControl: context.aFormGroup?.get(prop.key),
                  schema: prop.value,
                  controlName: prop.key,
                  view: context.view || '',
                  default: context?.default?.[prop.key]
                }
              }
            "
        ></ng-template>
      }
    }
  }
</ng-template>

<!-- Array Template -->
<ng-template #arrayTemplate let-context>
  @if (context?.schema?.[SchemaKeywords.UiWidget] === SfoUiWidgetType.EnumInput) {
    <div [ngClass]="context.schema[SchemaKeywords.UiClass] || 'col-12 py1'">
      <sfo-enum-input
        [aFormControl]="context.aFormArray"
        [schema]="context.schema"
        [defaultValue]="context.default"
        [title]="context.title"
      ></sfo-enum-input>
    </div>
  } @else {
    <div class="col-12">
      <div class="mb1 flex items-center justify-between">
        <div>
          <h2 *ngIf="context.schema?.title" class="h3 mb0 bold">
            {{context.schema?.title}}
          </h2>
          <small *ngIf="context.schema?.description" class="small">
            {{context.schema?.description}}
          </small>
        </div>
        <button mat-stroked-button (click)="addRow(context.aFormArray, context.schema.items)">
          Add
        </button>
      </div>

      @if (context.schema?.items?.type === 'object' && context.schema?.items?.properties) {
        <table class="col-12">
          <thead>
            <tr>
              <th
                *ngFor="let prop of context.schema?.items.properties | unsortedKeyvalue"
                [ngClass]="prop.value['_ui_table_width'] || 'col-12'"
              >
                <span class="bold" *ngIf="prop.value?.title">
                  {{prop.value.title || prop.key}}
                </span>
              </th>
              <th class="col-1">
                <span class="bold">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (control of context.aFormArray.controls; track control; let i = $index) {
              <tr>
                <td
                  *ngFor="let prop of context.schema?.items.properties | unsortedKeyvalue"
                  [ngClass]="prop.value['_ui_table_width'] || 'col-12'"
                >
                  @switch (prop.value.type) {
                    @case ('object') {
                      <ng-template
                        *ngTemplateOutlet="
                        objectTemplate;
                        context: {
                          $implicit: {
                            aFormGroup: context.aFormArray.at(i).get(prop.key),
                            schema: prop.value.properties,
                            controlName: prop.key,
                            depth: 0,
                            default: prop.value.default || (context.schema.default?.[i]?.[prop.key] || {})
                          }
                        }
                      "
                      ></ng-template>
                    }

                    @case ('array') {
                      <ng-template
                        *ngTemplateOutlet="
                        arrayTemplate;
                        context: {
                          $implicit: {
                            aFormArray: context.aFormArray.at(i).get(prop.key),
                            schema: prop.value.items,
                            controlName: prop.key,
                            depth: 0,
                            default: prop.value.default || (context.schema.default?.[i]?.[prop.key] || [])
                          }
                        }
                      "
                      ></ng-template>
                    }

                    @default {
                      <ng-template
                        *ngTemplateOutlet="
                        primitiveTemplate;
                        context: {
                          $implicit: {
                            aFormControl: context.aFormArray.at(i).get(prop.key),
                            schema: prop.value,
                            controlName: prop.key,
                            default: prop.value?.default || (context.schema.default?.[i]?.[prop.key] || '')
                          }
                        }
                      "
                      ></ng-template>
                    }
                  }
                </td>
                <td class="col-1">
                  <div class="flex items-center justify-center">
                    <button mat-icon-button (click)="removeRow(i, context.aFormArray)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      } @else {
        <div class="col-12">
          @for (control of context.aFormArray.controls; track control; let i = $index) {
            <div class="flex items-center mb2">
              <div class="flex-auto">
                <ng-template
                  *ngTemplateOutlet="
                  primitiveTemplate;
                  context: {
                    $implicit: {
                      aFormControl: context.aFormArray.at(i),
                      schema: context.schema?.items,
                      controlName: i.toString(),
                      default: context.schema?.default?.[i] || ''
                    }
                  }
                "
                ></ng-template>
              </div>
              <div class="ml2">
                <button mat-icon-button (click)="removeRow(i, context.aFormArray)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
</ng-template>

<!-- Primitive Template -->
<ng-template #primitiveTemplate let-context>
  <div
    [ngClass]="context.schema[SchemaKeywords.UiClass] || 'col-12 py1'"
    *ngIf="context.schema?.[SchemaKeywords.UiHide] !== true"
  >
    <sfo-dynamic-input
      [aFormControl]="context.aFormControl"
      [schema]="context.schema"
      [defaultValue]="context.default"
    ></sfo-dynamic-input>
  </div>
</ng-template>
