import { load } from 'js-yaml';
import { SfoUiJSONSchema7 } from '../metadata.model';
import Ajv, { MissingRefError } from 'ajv';
import { SchemaKeywords } from './types';

export class SfoFormHelperService {
  /**
   * Recursively removes `null`, `undefined`, and empty string values from an object or array.
   * If the entire object or array is reduced to `null`, it returns `null`. This function
   * takes into account the provided JSON Schema, and preserves empty strings when
   * a property has `minLength: 0` or `keepFalsy: true`.
   *
   * @template T - The type of the object being processed.
   * @param {T} obj - The object or array to clean.
   * @param {object} [schema={}] - The JSON Schema Draft 07 that describes the structure
   * of the object, used to determine if empty/falsy values should be preserved.
   * @returns {any} - The cleaned object or array, or `null` if all values are removed.
   */
  static removeNullValues<T>(obj: T, schema: any = {}): any {
    if (obj === null || obj === undefined) {
      return schema?._nullable ? obj : undefined;
    }

    if (Array.isArray(obj)) {
      if (schema?._nullable === true) {
        const filteredArray = obj
          .map((item) => {
            if (item === null) return null;
            if (item === '') return schema?.minLength === 0 ? '' : undefined;
            return this.removeNullValues(item, schema.items);
          })
          .filter((item) => item !== undefined);

        return filteredArray;
      }

      const filteredArray = obj
        .map((item) => this.removeNullValues(item, schema.items))
        .filter((item) => {
          if (item === null || item === undefined) return false;
          if (item === '') return false;
          if (Array.isArray(item) && item.length === 0) return false;
          if (typeof item === 'object' && item !== null && Object.keys(item).length === 0)
            return false;
          return true;
        });

      return filteredArray.length ? filteredArray : [];
    }

    if (typeof obj === 'object' && obj !== null) {
      const cleanedObject = Object.keys(obj).reduce((acc, key) => {
        const value = (obj as any)[key];
        const schemaForKey = schema.properties ? schema.properties[key] : {};
        const isNullable = schemaForKey?._nullable === true;
        const hasMinLengthZero = schemaForKey?.minLength === 0;

        if (value === null && isNullable) {
          (acc as any)[key] = null;
          return acc;
        }

        const processedValue = this.removeNullValues(value, schemaForKey);

        if (processedValue === null || processedValue === undefined) {
          if (isNullable) {
            (acc as any)[key] = processedValue;
          }
        } else if (processedValue === '') {
          if (hasMinLengthZero) {
            (acc as any)[key] = '';
          }
        } else if (Array.isArray(processedValue) && processedValue.length === 0) {
          if (isNullable) {
            (acc as any)[key] = [];
          }
        } else {
          (acc as any)[key] = processedValue;
        }

        return acc;
      }, {} as Partial<T>);

      return Object.keys(cleanedObject).length > 0 ? cleanedObject : undefined;
    }
    return obj;
  }

  /**
   * Recursively removes properties from an instance that match the default values defined in a schema.
   * This function works with nested objects and arrays, and removes keys whose values are equal to the schema's defaults.
   *
   * @param {object} jsonSchemaInstance - The instance object to clean.
   * @param {object} jsonSchema7 - The JSON Schema that defines the structure and default values.
   * @returns {object} - The cleaned instance with default-matching properties removed.
   */
  static removeDefaults(jsonSchemaInstance: object, jsonSchema7: object): object {
    if (!jsonSchemaInstance) {
      return {};
    }

    // Copy the instance to avoid modifying the original
    let cleanedInstance = structuredClone(jsonSchemaInstance);

    // Iterate over each key in the instance
    for (const key in jsonSchemaInstance) {
      const instanceValue = jsonSchemaInstance[key];
      const schemaProperty = jsonSchema7?.['properties']?.[key];

      // Check if the current instance key has a corresponding schema property
      if (schemaProperty) {
        const schemaDefault = schemaProperty.default;

        if (Array.isArray(instanceValue)) {
          // Check if the array matches the schema default array
          if (
            Array.isArray(schemaDefault) &&
            instanceValue.every((item, index) => item === schemaDefault[index])
          ) {
            delete cleanedInstance[key];
          }
        } else if (typeof instanceValue === 'object' && instanceValue !== null) {
          // Recurse if the instance value is an object
          const nestedSchema = schemaProperty;
          const cleanedNestedInstance = this.removeDefaults(instanceValue, nestedSchema);

          // If the cleaned nested instance is an empty object, remove the key
          if (
            Object.keys(cleanedNestedInstance).length === 0 &&
            !Array.isArray(cleanedNestedInstance)
          ) {
            delete cleanedInstance[key];
          } else {
            cleanedInstance[key] = cleanedNestedInstance;
          }
        } else {
          // Only remove if the instance value strictly equals the schema default
          if (instanceValue === schemaDefault) {
            delete cleanedInstance[key];
          }
        }
      }
    }

    return cleanedInstance;
  }

  /**
   * Parses a YAML string and returns its equivalent JavaScript object, string, or number.
   * Throws an error if the YAML is invalid.
   *
   * @param {string} yamlString - The YAML string to be parsed.
   * @returns {object | string | number | null} - The parsed YAML object, string, number, or null if parsing fails.
   * @throws {Error} If the YAML string is invalid or cannot be parsed.
   */
  static loadYaml(yamlString: string): object | string | number | null {
    try {
      return load(yamlString);
    } catch (e) {
      throw e;
    }
  }

  /**
   * Validates a patch object against a given JSON schema.
   *
   * @param {SfoUiJSONSchema7 | null} schema - The JSON schema to validate against.
   * @param {unknown} patch - The patch object to validate.
   * @returns {{isValid: boolean, errors: unknown | unknown[]}} - Returns an object containing a boolean `isValid` indicating whether the patch is valid and an `errors` array or object of validation errors.
   * @throws {Error} If a schema reference cannot be resolved or validation fails unexpectedly.
   */
  static isPatchValid(
    schema: SfoUiJSONSchema7 | null,
    patch: unknown,
  ): { isValid: boolean; errors: unknown | unknown[] } | undefined {
    const ajv = new Ajv({
      allErrors: true,
      strict: true,
      allowUnionTypes: true,
      // format is a reserved keyword for ajv - we can use it to pass keywords without validation
      formats: {
        'sfo-rich-text': true,
        'textarea': true,
      },
    });

    Object.values(SchemaKeywords).forEach((keyword) => {
      ajv.addKeyword(keyword);
    });

    if (!schema) {
      console.error('No schema provided.');
      return;
    }

    try {
      const validate = ajv.compile(schema);
      const valid = validate(patch);

      if (!valid) {
        console.error(validate.errors);
        return { isValid: false, errors: validate.errors };
      }

      return { isValid: true, errors: [] };
    } catch (e) {
      if (e instanceof MissingRefError) {
        throw new Error(
          `[Form Helper] Validation cannot resolve $ref.\nSchema: ${e?.['missingSchema']}\nRef: ${e?.['missingRef']}`,
        );
      }

      console.error('[Form Helper] Schema is invalid: ', e);
      throw new Error(`Schema is invalid: ${e?.['message']}`);
    }
  }
}
