<mat-tab-group class="col-12" animationDuration="0ms" [selectedIndex]="selectedTabIndex">
  <mat-tab *ngFor="let tab of tabs" [label]="tab.label">
    <div class="p2">
      <ng-container *ngIf="tab.isGroup">
        <div *ngFor="let control of tab.controls">
          <sfo-dynamic-field
            [schema]="schema.properties?.[control]"
            [aFormControl]="aFormControl"
            [renderMode]="'quickSettings'"
            [control]="control"
          ></sfo-dynamic-field>
        </div>
      </ng-container>

      <!-- Handle single field tabs -->
      <ng-container *ngIf="!tab.isGroup">
        <sfo-admonition
          *ngIf="schema?.properties?.[tab.controls[0]]?._readme"
          title="About"
          type="info"
          expanded
        >
          <p [innerHTML]="schema.properties?.[tab.controls[0]]?._readme | markdown"></p>
        </sfo-admonition>
        <sfo-dynamic-field
          [schema]="schema.properties?.[tab.controls[0]]"
          [aFormControl]="aFormControl"
          [renderMode]="'quickSettings'"
          [control]="tab.controls[0]"
        ></sfo-dynamic-field>
      </ng-container>
    </div>
  </mat-tab>
</mat-tab-group>
