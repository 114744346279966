@if (schema && control) {
  <div class="py2 px3">
    <!-- Header -->
    <div class="flex justify-start pb2">
      <div class="flex flex-column lg-col-8 md-col-10 col-12">
        <h1 class="h1">
          @if (schema && schema.properties?.[control]?.title !== undefined) {
            {{ schema.properties?.[control]?.title }}
          } @else {
            {{ control }}
          }
        </h1>

        <span *ngIf="schema.properties?.[control]?.description">
          {{ schema.properties?.[control]?.description }}
        </span>

        <sfo-admonition
          *ngIf="schema?.properties?.[control]?._readme"
          title="About"
          type="info"
          expanded
        >
          <p [innerHTML]="schema.properties?.[control]?._readme | markdown"></p>
        </sfo-admonition>
      </div>
    </div>

    <!-- Body -->
    <div class="col-12">
      <sfo-dynamic-field
        [schema]="schema.properties?.[control]"
        [aFormControl]="aFormControl"
        [renderMode]="'simple'"
        [control]="control"
      ></sfo-dynamic-field>
    </div>
  </div>
} @else {
  <div class="mt4 col-6 mx-auto">
    <sfo-admonition type="info" title="Getting Started" expanded hideToggle>
      <p>Please select a category from the left to change the {{schema?.title}}.</p>
    </sfo-admonition>
  </div>
}
