import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { nodeMetaDataJsonSchema7, SFNodeType } from '@sciflow/schema';
import { EditorService } from 'editor';
import { selectTemplate } from 'export';
import { NodeMetaEditorDialog } from 'projects/ui-components/src';
import { map, Subject, switchMap, takeUntil } from 'rxjs';
import { deleteNodeById, updateNodeAttrs } from '../../commands';

@Component({
  selector: 'sfo-quick-actions-menu',
  templateUrl: './quick-actions-menu.component.html',
  styleUrls: ['./quick-actions.menu.component.scss'],
  standalone: false,
})
export class QuickActionsMenuComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private view;
  private schema;

  node;
  readonly SFNodeType = SFNodeType;

  private readonly currentView$ = this.editorService.currentView.pipe(
    takeUntil(this.destroy$),
    map((view) => {
      this.view = view;
      this.node = view?.state?.selection?.['node'];
      return { view, node: this.node };
    }),
  );

  readonly schema$ = this.currentView$.pipe(
    switchMap(({ node }) =>
      this.store.select(selectTemplate).pipe(
        map((template) => {
          const nodeType = node?.type?.name;
          this.schema =
            template?.prosemirrorNodeSchemas?.[nodeType] ?? nodeMetaDataJsonSchema7[nodeType];
        }),
      ),
    ),
  );

  constructor(
    private dialog: MatDialog,
    private editorService: EditorService,
    private store: Store,
  ) {
    this.schema$.subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  deleteNode() {
    deleteNodeById(this.node?.attrs?.id)(this.view.state, this.view.dispatch);
  }

  openEditDialog(renderMode: string) {
    const dialogRef: MatDialogRef<NodeMetaEditorDialog> = this.dialog.open(NodeMetaEditorDialog, {
      data: {
        node: this.node,
        schema: this.schema,
        renderMode,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        updateNodeAttrs(this.node?.attrs?.id, result)(this.view.state, this.view.dispatch);
      }
    });
  }
}
