import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SfoUiJSONSchema7 } from '../../../../metadata.model';

@Component({
  selector: 'sfo-radio-group-input',
  templateUrl: './radio-group-input.component.html',
  standalone: false,
})
export class RadioGroupInputComponent implements OnInit {
  @Input() schema!: SfoUiJSONSchema7;
  @Input() aFormControl!: FormControl;
  @Input() defaultValue: any;

  ngOnInit(): void {
    if (this.aFormControl.value === undefined) {
      const valueToSet = this.defaultValue ?? this.schema.default;
      if (valueToSet !== undefined) {
        this.aFormControl.setValue(valueToSet, { emitEvent: false });
      }
    }
  }
}
