import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SfoUiJSONSchema7 } from '../../metadata.model';
import { RenderMode } from '../types/view.types';
import { CommonModule } from '@angular/common';
import { DynamicFieldModule } from '../form-fields';

@Component({
  selector: 'sfo-simple-view',
  templateUrl: './simple-view.component.html',
  imports: [CommonModule, DynamicFieldModule],
})
export class SimpleViewComponent {
  @Input() schema: SfoUiJSONSchema7;
  @Input() aFormControl: FormGroup;
  @Input() renderMode: RenderMode | undefined;

  get controlsInOrder(): string[] {
    return Object.keys(this.aFormControl.controls);
  }

  getFormControl(control: string): FormGroup {
    return this.aFormControl.get(control) as FormGroup;
  }
}
