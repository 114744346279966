import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EditorView } from 'prosemirror-view';
import { Node } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  currentView = new BehaviorSubject<EditorView | null>(null);

  constructor(){}

  changeView(view) {
    this.currentView.next(view);
  }
}
