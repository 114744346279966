import { Pipe, PipeTransform } from '@angular/core';
import { SfoUiJSONSchema7 } from '../../metadata.model';

@Pipe({
  name: 'hasEnum',
  pure: true,
})
export class HasEnumPipe implements PipeTransform {
  transform(schema: SfoUiJSONSchema7): boolean {
    if (schema.enum) return true;
    if (schema.anyOf?.find((s) => s?.['enum'])) return true;
    if (schema.oneOf?.find((s) => s?.['enum'])) return true;

    return false;
  }
}
