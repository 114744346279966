<div class="flex flex-column cursor-move" draggable="true">
    <div class="flex justify-end">
        <button mat-icon-button *ngIf="!captionData?.hasCaption" (click)="addCaption()" matTooltip="Add a caption">
            <mat-icon>notes</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isImageFigure(figureNode)" (click)="uploadImage()"
            matTooltip="Replace the image">
            <mat-icon svgIcon="figure-replace-figure-image"></mat-icon>
        </button>
        <button mat-icon-button (click)="delete()" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="moreOptions" aria-label="Example icon-button with a menu" *ngIf="figureNode.attrs.type !== 'code'">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #moreOptions="matMenu">
            <button mat-menu-item *ngIf="figureNode.attrs?.orientation === 'portrait'" (click)="orientate('landscape')"
                matTooltip="Switch to landscape in PDF exports">
                <mat-icon>text_rotation_down</mat-icon>
                <span>Switch to landscape</span>
            </button>
            <button mat-menu-item *ngIf="figureNode.attrs?.orientation === 'landscape'" (click)="orientate('portrait')"
                matTooltip="Switch to portrait">
                <mat-icon>text_rotation_none</mat-icon>
                <span>Switch to portrait</span>
            </button>
        </mat-menu>
    </div>
</div>

<div class="image flex justify-center m2" *ngIf="isEmptyImageFigure(figureNode)">
    <img *ngIf="figureNode.attrs.src?.length > 0; else placeholder" [src]="figureNode.attrs.src">
    <ng-template #placeholder>
        <div class="flex flex-column justify-center max-width-1">
            <div>
                <mat-spinner *ngIf="isUploading" class="mx-auto"></mat-spinner>
                <img *ngIf="!isUploading" class="placeholder" src="assets/sf-icons/figure.svg">
            </div>
            <button (click)="uploadImage()" [disabled]="isUploading" mat-raised-button color="primary">Upload an image</button>
            <div class="drop-zone h6 m2 p2" [ngClass]="{ 'drop-zone-active': isDropTarget }">Drag any svg, gif, jpg, tiff or png onto this area.</div>
        </div>
    </ng-template>
</div>


<div
  class="image flex flex-column items-center my2 col-12"
  *ngIf="!isEmptyImageFigure(figureNode)"
  [ngClass]="{ 'drop-zone-active': isDropTarget }"
>
  <div class="image-container" *ngIf="figureNode.attrs?.src">
    <img
      class="drop-zone h6 p2"
      [src]="figureNode.attrs.src"
      matTooltip="Preview only"
      [attr.alt]="figureNode.attrs?.decorative ? '' : figureNode.attrs?.alt || null"
      [attr.role]="figureNode.attrs?.decorative ? 'presentation' : null"
    />
    <div
      class="overlay"
      [ngClass]="{'overlay-warning': !figureNode.attrs?.alt && !figureNode.attrs?.decorative}"
    >
      <div class="flex items-center">
        @if (figureNode.attrs?.decorative) {
           <p 
            class="overlay-text px1 m0" matTooltip="This image is marked as decorative.">
            (Decorative)
          </p>
        } @else {
          <p 
            class="overlay-text px1 m0" [matTooltip]="figureNode.attrs?.alt ?? 'No alt available'">
            Alt: {{figureNode.attrs?.alt || '(No alt available)'}}
          </p>
        }
        <button
          mat-icon-button
          class="overlay-button"
          matTooltip="Edit alt text"
          (click)="openEditDialog()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="image flex justify-center m2" *ngIf="isEmptyNativeTable(figureNode)">
    <div class="flex flex-column justify-center max-width-1">
        <div>
            <mat-spinner *ngIf="isUploading" class="mx-auto"></mat-spinner>
            <img *ngIf="!isUploading" class="placeholder" src="assets/sf-icons/table.svg">
        </div>
        <span class="info">Upload an <b>image table</b> here instead. It will be listed as a table.</span>
        <button (click)="uploadImage()" mat-raised-button color="primary">Upload an image instead</button>
    </div>
</div>

<form action="" class="display-none" enctype="multipart/form-data">
    <input #fileUpload type="file" (change)="onUpload($event)">
</form>