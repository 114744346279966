import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SfoAdmonitionModule } from './admonition';
import { SfoRichTextModule } from './rich-text';

@NgModule({
  imports: [CommonModule, SfoAdmonitionModule, SfoRichTextModule],
  exports: [SfoAdmonitionModule, SfoRichTextModule],
})
export class SfoWidgetModule {}
