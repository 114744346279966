import { Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { nodeMetaDataJsonSchema7 } from '@sciflow/schema';
import { selectTemplate } from 'export';
import { map, Observable } from 'rxjs';

export interface SchemaProvider {
  getSchema(nodeType: string): Observable<any>;
}

@Injectable()
export class StoreSchemaProvider implements SchemaProvider {
  constructor(private store: Store) {}

  getSchema(nodeType: string) {
    return this.store
      .select(selectTemplate)
      .pipe(
        map(
          (template) =>
            template?.prosemirrorNodeSchemas?.[nodeType] ?? nodeMetaDataJsonSchema7?.[nodeType],
        ),
      );
  }
}

export const SCHEMA_PROVIDER = new InjectionToken<SchemaProvider>('SCHEMA_PROVIDER');
