import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SfoUiJSONSchema7} from '../../../metadata.model';

@Component({
  selector: 'drawer-view-content',
  templateUrl: './drawer-view-content.component.html',
  standalone: false,
})
export class DrawerViewContentComponent {
  @Input() control: string = '';
  @Input() schema: SfoUiJSONSchema7 | null;
  @Input() aFormControl: FormGroup | null;
}
