<mat-form-field floatLabel="auto" appearance="fill" class="col-12" subscriptSizing="dynamic">
  <mat-label>
    {{ schema.title }}
  </mat-label>
  @if (schema.examples) {
    <mat-select
      [formControl]="aFormControl"
      [multiple]="schema[SchemaKeywords.Multiple] === true"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-option
        *ngFor="let example of schema.examples"
        [value]="example['value']"
        [matTooltip]="example?.['description']"
      >
        {{example?.['label']}}
      </mat-option>
    </mat-select>
  } @else {
    <mat-select
      [formControl]="aFormControl"
      [multiple]="schema[SchemaKeywords.Multiple] === true"
      [(value)]="aFormControl.value"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-option *ngFor="let value of schema.enum" [value]="value">
        {{value}}
      </mat-option>
    </mat-select>
  }
  <mat-hint *ngIf="schema.description">{{ schema.description }}</mat-hint>
</mat-form-field>
