import { SFNodeType } from '@sciflow/schema';

export interface Event {
  type: string;
  id: string;
  pos: number;
  coords: { top: number; left: number };
  node: any;
  inFilteredNode: boolean;
  parent?: any;
}

/**
 * Matches a ProseMirror node at a given document position and returns an event object with node details.
 *
 * This function resolves the provided position in the ProseMirror editor view and traverses up the document tree
 * until it finds a node whose type matches one of the specified types. It then calculates the screen coordinates for
 * the node's position and returns an object containing details about the matched node, including its type, id,
 * position, coordinates, the node itself, and its parent if applicable.
 *
 * @param {any} view - The ProseMirror editor view instance.
 * @param {number} selectedPos - The position in the document where the matching begins.
 * @param {SFNodeType[]} [types=[SFNodeType.heading, SFNodeType.paragraph, SFNodeType.blockquote]]
 *   - An optional array of node types to match against.
 * @param {string[]} [filterNodeTypes=[SFNodeType.figure, SFNodeType.code, SFNodeType.math]]
 *   - An optional array of node types that should cause insideFilteredNode to be true.
 * @returns {(Event | null)} An event object containing details of the matched node, or null if no match is found.
 */
export const matchNode = (
  view,
  selectedPos: number,
  types = [SFNodeType.heading, SFNodeType.paragraph, SFNodeType.blockquote, SFNodeType.header, SFNodeType.subtitle],
  filterNodeTypes = [SFNodeType.figure, SFNodeType.code, SFNodeType.math],
): Event | null => {
  const state = view.state;
  const resPos = state.doc.resolve(selectedPos);

  let insideFilteredNode = false;

  for (let depth = resPos.depth; depth > 0; depth--) {
    const node = resPos.node(depth);
    const nodeType = node.type.name;

    if (filterNodeTypes.includes(nodeType)) {
      insideFilteredNode = true;
      break;
    }
  }

  // start at the current position and go up the document tree
  // until we reach a node we want to change (like a paragraph)
  let offset = resPos.parentOffset; // TODO reset offset at lower levels
  for (let depth = resPos.depth; depth >= 0; depth--) {
    const node = resPos.node(depth);
    if (types.includes(node.type.name)) {
      const coords = view.coordsAtPos(selectedPos - offset);
      return {
        type: node.type.name,
        id: node.attrs?.id,
        pos: selectedPos - offset,
        coords,
        node,
        parent,
        inFilteredNode: insideFilteredNode,
      };
    }
  }

  return null;
};
