import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SFNodeType } from '@sciflow/schema';
import { NodeMetaEditorDialog, SfoUiJSONSchema7 } from 'projects/ui-components/src';
import { Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { Subject, takeUntil } from 'rxjs';
import { Command } from '../../menu.service';
import { deleteNodeById, updateNodeAttrs } from '../../prosemirror/commands';
import { SCHEMA_PROVIDER } from './schema-provider.interface';

@Component({
  selector: 'sfo-pm-node-menu',
  templateUrl: './node-menu.component.html',
  styleUrls: ['./node-menu.component.scss'],
  standalone: false,
})
export class PmNodeMenuComponent implements OnInit, OnDestroy {
  private dialogRef?: MatDialogRef<NodeMetaEditorDialog>;
  private dialog: MatDialog = inject(MatDialog);
  private schemaProvider = inject(SCHEMA_PROVIDER);
  private destroy$: Subject<void> = new Subject<void>();
  private sfNodeType: SFNodeType;

  @Input() editorView!: EditorView;
  @Input() node!: Node;

  prosemirrorNodeSchema: SfoUiJSONSchema7;

  ngOnInit(): void {
    this.sfNodeType = this.node.type.name as SFNodeType;
    const schema = this.editorView.state.schema.nodes[this.sfNodeType];

    if (!schema) {
      throw new Error(`[Part Menu] Node type '${this.sfNodeType}' not found in schema`);
    }

    this.schemaProvider
      .getSchema(this.sfNodeType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((schema) => (this.prosemirrorNodeSchema = schema));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  async openDialog() {
    if (this.dialogRef) {
      return;
    }
    try {
      const DeleteNode: Command = {
        id: 'delete',
        materialIconName: 'delete',
        tooltip: 'Delete this part',
        run: deleteNodeById(this.node.attrs.id),
        active: true,
      };

      this.dialogRef = this.dialog.open(NodeMetaEditorDialog, {
        minWidth: '80vw',
        height: '90vh',
        data: {
          node: this.node,
          schema: this.prosemirrorNodeSchema,
          renderMode: 'tabbed',
          commands: [DeleteNode],
          editorView: this.editorView,
        },
        autoFocus: true,
        restoreFocus: true,
        panelClass: 'editor-dialog-panel',
      });

      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const id = result?.id || this.node?.attrs.id;
          updateNodeAttrs(id, result)(this.editorView?.state, this.editorView?.dispatch);
        }

        this.dialogRef = undefined;
      });
    } catch (e) {
      console.error('[Part Menu] Error validating node type:', e);
    }
  }
}
