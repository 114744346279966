export interface ContribAuthor {
    htmlElId?; string;
    name: string;
    givenName: string;
    surname: string;
    additionalName: string;
    nonWesternName: {
        familyNameFirst: boolean;
        nativeScriptName: string;
    };
    identifiers: ContribAuthorIdentifier[];
    jobTitle: string;
    affiliation: ContribAffiliation[];
    roleName: ContributorRole[];
    correspondingAuthor: boolean;
    email: string;
    description: string;
    biography: string;
    hasPart: {
        text: string;
    };
    funding: ContribFunding[];
    associatedGroups: ContribGroup[];
    deceased: boolean;
}

// Sub-types used within Contrib
export interface ContribAuthorIdentifier {
    type: "orcid" | "isni" | "scopus" | "researcherid" | "google_scholar" | "viaf" | "other";
    id: string;
}

export interface ContribAffiliation {
    htmlElId?; string;
    "@id": string | null; // Affiliation reference (e.g., ROR ID), used to uniquely identify an institution.
    name: string; // Institution name as it appears in formal records.
    department: string | null; // Department or faculty within the institution, if applicable.
    formattedName: string | null; // Complete affiliation as a formatted string, preserving publisher-preferred ordering.

    parentOrganization: string | null; // Larger organization overseeing this affiliation.
    alternateNames: string[] | null; // Alternative names for the institution (e.g., translations, abbreviations).
    identifiers: string[] | null; // Alternative institutional identifiers such as GRID, ISNI, or Wikidata.

    address: {
        streetAddress: string | null; // Street address for the institution, if available.
        postalCode: string | null; // Postal code of the institution's location.
        addressLocality: string | null; // City or locality where the institution is located.
        addressCountry: string | null; // Country where the institution is located.
    }[] | null; // Address details for institutions with multiple campuses or locations.
}

export type ContributorRole =
    | "Conceptualization"
    | "Data Curation"
    | "Formal Analysis"
    | "Funding Acquisition"
    | "Investigation"
    | "Methodology"
    | "Project Administration"
    | "Resources"
    | "Software"
    | "Supervision"
    | "Validation"
    | "Visualization"
    | "Writing – Original Draft"
    | "Writing – Review & Editing";

export interface ContribFunding {
    funderName: string;
    funderIdentifier: string;
    grantNumber: string;
    projectTitle: string;
}

export interface ContribGroup {
    name: string;
    identifier: string;
    role: string;
}
