import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { initializeAuthors } from 'author-management';
import { registerInstance, updateImageResources } from 'editor';
import { updateMetadata } from 'export';
import { RenderingService, setReferences } from 'reference-management';
import { debounceTime, take } from 'rxjs/operators';
import { FileService } from 'shared';
import { selectManuscriptFile } from '../../../app/file.reducer';
import { projectsList } from '../../project/project.actions';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private apollo: Apollo,
    private store: Store,
    private fileService: FileService,
    private renderingService: RenderingService,
  ) { }

  loadFiles(projectId: string) {
    return this.apollo.watchQuery<any>({
      query: gql`query Project($projectId: String!) {
        project(projectId: $projectId) {
          id
          projectId
          size
          resources {
            Key
            size
            LastModified
            ETag
            versions {
              LastModified
              VersionId
            }
          }
        }
      }`,
      variables: { projectId },
    });
  }

  /** Fetches the project's references */
  async getReferences(projectId: string) {
    const result: any = await this.apollo
      .query<any>({
        query: gql`query references($projectId: String!) {
        references(projectId: $projectId) {
          Key
          LastModified
          items {
            key
            csl
            title
            itemType
            tags
            bib
          }
        }
      }`,
        fetchPolicy: 'network-only',
        variables: { projectId },
      })
      .toPromise();
    return result?.data?.references?.map((file) => file.items).flat() || [];
  }

  /** Fetches a document */
  async getDocument(projectId, documentName, { version }) {
    console.log('Loading document', { projectId, documentName, version });

    const id = FileService.getFileIdFromName(documentName);
    let documentResult: any;
    try {
      documentResult = await this.apollo
        .query({
          query: gql`query GetDocument($key: String!, $version: String) {
        document(key: $key, version: $version, processCitations: true, events: []) {
          key
          logs
          manuscript {
            document
            authors
            metaData
            references
            lastModified
            template
            files {
              id
              name
              type
              url
            }
          }
        }
      }`,
          variables: {
            key: `${projectId}/${documentName}`,
            version
          },
        })
        .toPromise();
    } catch (e: any) {
      return { errors: e.graphQLErrors };
    }

    const { key, manuscript } = documentResult.data?.document;
    this.store.dispatch(
      initializeAuthors({
        unrestricted: true,
        authors: manuscript.authors?.map((author) => ({ ...author, currentUser: false })),
      }),
    );
    const payload = {
      id,
      key,
      version,
      projectId,
      lastModified: manuscript.lastModified,
      state: {
        doc: manuscript.document,
        files: manuscript.files || [],
      },
    };
    this.store.dispatch(
      registerInstance(payload),
    );
    console.log('Initializing document', { key, manuscript });
    this.store.dispatch(projectsList({ projectsAvailable: true }));

    if (manuscript?.references?.length > 0) {
      const bibliography = this.renderingService.getBibliography(manuscript.references);
      const references = manuscript.references.map((csl, index) => ({
        key: csl.id,
        csl,
        bib: bibliography[index] ?? csl.id,
      }));
      this.store.dispatch(setReferences({ references }));
    }
    this.store.dispatch(updateMetadata({ metaData: manuscript.metaData }));

    // update the image resources to the store
    let projectResources: any = (await this.loadFiles(projectId).refetch()).data;
    projectResources = projectResources.project?.resources;
    const project = projectResources
      .map((resource) => {
        const fileName = resource.Key.replace(`${projectId}/`, '');
        const ext = fileName.split('.')[fileName.split('.').length - 1];

        return {
          id: FileService.getFileIdFromName(fileName),
          projectId,
          ext,
          ...resource,
          fileName,
        };
      })
      .filter((res) => res.ext !== 'docx' && res.ext !== 'json' && res.ext !== 'bib');
    this.store.dispatch(updateImageResources({ imageResources: project }));

    return documentResult;
  }

  /** Saves the current state of the document to the server */
  async persistStore() {
    // wait 400ms for events to settle and then get the snapshot
    const data = await this.store
      .select(selectManuscriptFile)
      .pipe(debounceTime(400), take(1))
      .toPromise();
    if (!data?.id) {
      return;
    }
    console.log('Persisting store', data);
    const fileArray = data.id.split('/');
    const fileName = fileArray[fileArray.length - 1];
    const prefix = fileArray.slice(0, fileArray.length - 1).join('/');
    const file = new File([JSON.stringify(data?.manuscript, null, 2)], fileName + '.json', {
      type: 'application/json'
    });
    const result: any = await this.fileService.uploadFile(data.projectId, file, prefix);
    return {
      result,
      data,
    };
  }
}
