import { NgModule } from '@angular/core';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { SharedModule } from '../shared';
import { SfoWidgetModule } from '../widgets';
import { DrawerViewModule } from './drawer-view';
import { DynamicFormComponent } from './dynamic-form.component';
import { DynamicFieldModule } from './form-fields';
import { SfoFormHelperService } from './form-helper.class';
import { QuickSettingsModule } from './quicksettings-view';
import { SimpleViewComponent } from './simple-view/simple-view.component';
import { TabViewComponent } from './tab-view/tab-view.component';

@NgModule({
  declarations: [DynamicFormComponent],
  imports: [
    SharedModule,
    SimpleViewComponent,
    TabViewComponent,
    DynamicFieldModule,
    DrawerViewModule,
    QuickSettingsModule,
    SfoWidgetModule,
  ],
  exports: [DynamicFormComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    SfoFormHelperService,
  ],
})
export class SfoDynamicFormModule {}
