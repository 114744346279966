import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { nodeMetaDataJsonSchema7, SFNodeType } from '@sciflow/schema';
import { selectTemplate } from 'export';
import { NodeMetaEditorDialog } from 'projects/ui-components/src';
import { firstValueFrom, map } from 'rxjs';
import { FigurePreviewModalComponent } from '../figure-preview-modal/figure-preview-modal.component';

@Component({
  selector: 'sf-figure-menu',
  templateUrl: './figure-menu.component.html',
  styleUrls: ['./figure-menu.component.scss'],
  standalone: false,
})
export class FigureMenuComponent implements OnInit {
  figureNode: { [key: string]: any };
  @Input() set node(value) {
    this.figureNode = value;
  }

  @Input('document-id') documentId;
  @Input() isUploading = false;
  @Input() isDropTarget = false;
  @Input() captionData;
  @Input() file;

  @ViewChild('fileUpload', { static: true }) fileUploadButton: ElementRef;

  @Output() commands = new EventEmitter();

  emit(command: { id: string; payload?: any }) {
    this.commands.emit(command);
  }

  constructor(
    public dialog: MatDialog,
    private store: Store,
  ) {}

  delete() {
    this.emit({ id: 'delete' });
  }

  addCaption() {
    this.emit({ id: 'add-caption' });
  }

  uploadImage() {
    this.fileUploadButton.nativeElement.click();
  }

  async onUpload(event) {
    const files = (event.srcElement || event.target).files;
    this.isUploading = true;
    // delete this.figureNode.attrs.src;

    this.emit({ id: 'upload-image', payload: { files } });
  }

  getBase64(file): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  isEmptyImageFigure(node) {
    return node.attrs.type === 'figure' && (!node.attrs.src || node.attrs.src.length === 0);
  }

  zoom(file?: { id: string; url: string }) {
    this.dialog
      .open(FigurePreviewModalComponent, {
        data: {
          documentId: this.documentId,
          figureId: this.figureNode.attrs.id,
          thumbnail: this.figureNode.attrs.src,
          node: this.figureNode,
          file,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.emit({ id: 'set-selection' });
      });
  }

  isEmptyNativeTable(node) {
    return (
      node.attrs.type === 'table' &&
      !node.attrs.src &&
      node.content.content.find((node) => node.type.name === 'table') == null
    );
  }

  isImageFigure(node) {
    if (!this.isEmpty(node) && !this.isActiveNativeTable(node)) {
      return this.figureNode.attrs.type;
    }
  }

  isEmpty(node): boolean {
    // we neither have a (native table nor a table image)
    return !(this.isActiveNativeTable(node) || node.attrs.src);
  }

  isActiveNativeTable(node) {
    return (
      node.attrs.type === 'table' &&
      node.content.content.find((node) => node.type.name === 'table') != null
    );
  }

  orientate(orientation: 'landscape' | 'portrait') {
    this.emit({ id: 'rotate' });
  }

  async openEditDialog() {
    try {
      const nodeSchema = await firstValueFrom(
        this.store.select(selectTemplate).pipe(map((template) => template?.prosemirrorNodeSchemas)),
      );

      const dialogRef: MatDialogRef<NodeMetaEditorDialog> = this.dialog.open(NodeMetaEditorDialog, {
        data: {
          node: this.figureNode,
          schema: nodeSchema?.[SFNodeType.figure] || nodeMetaDataJsonSchema7?.[SFNodeType.figure],
          renderMode: 'simple',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.emit({
            id: 'update-alt-text',
            payload: result,
          });
        }
      });
    } catch (error) {
      console.error('Error in openEditDialog:', error);
    }
  }

  ngOnInit() {}
}
