import { NgModule } from '@angular/core';

import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { SharedModule } from '../../shared';
import { SfoWidgetModule } from '../../widgets';
import { DynamicFieldComponent } from './dynamic-field.component';
import { BooleanInputComponent } from './dynamic-input/boolean-input';
import { DateInputComponent } from './dynamic-input/date-input';
import { DynamicInputComponent } from './dynamic-input/dynamic-input.component';
import { EnumInputComponent } from './dynamic-input/enum-input';
import { IntegerInputComponent } from './dynamic-input/integer-input';
import { NumberInputComponent } from './dynamic-input/number-input';
import { RadioGroupInputComponent } from './dynamic-input/radio-group-input/radio-group-input.component';
import { TextareaInputComponent } from './dynamic-input/textarea/textarea.component';

import { HandleSchemaTypePipe } from './handle-schema-type.pipe';
import { HasEnumPipe } from './has-enum.pipe';
import { HasMultipleKeysPipe } from './has-multiple-keys.pipe';
import { UnsortedKeyvaluePipe } from './unsorted-key-value.pipe';

@NgModule({
  declarations: [
    BooleanInputComponent,
    DateInputComponent,
    DynamicFieldComponent,
    DynamicInputComponent,
    EnumInputComponent,
    IntegerInputComponent,
    NumberInputComponent,
    UnsortedKeyvaluePipe,
    HasMultipleKeysPipe,
    HandleSchemaTypePipe,
    TextareaInputComponent,
    RadioGroupInputComponent,
  ],
  imports: [SharedModule, SfoWidgetModule, HasEnumPipe],
  exports: [
    DynamicFieldComponent,
    BooleanInputComponent,
    DynamicInputComponent,
    EnumInputComponent,
    DateInputComponent,
    IntegerInputComponent,
    NumberInputComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    provideMomentDateAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD',
      },
      display: {
        dateInput: 'YYYY-MM-DD', // <-- for displaying
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
      },
    }),
  ],
})
export class DynamicFieldModule {}
