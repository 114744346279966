<div class="flex flex-column mat-elevation-z1 menu">
  <button
    mat-icon-button
    *ngIf="node?.type?.name === SFNodeType.part"
    matTooltip="Edit the chapter settings"
    matTooltipPosition="right"
    (click)="openEditDialog('tabbed')"
  >
    <mat-icon>edit</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="node?.type?.name === SFNodeType.image"
    [matTooltip]="node?.attrs?.decorative ? 'Edit Alt Text (image is decorative)' : node?.attrs?.alt ? 'Edit Alt Text' : 'Add Alt Text (currently missing)'"
    matTooltipPosition="right"
    (click)="openEditDialog('simple')"
  >
    <mat-icon [matBadge]="!node?.attrs?.alt && !node?.attrs?.decorative ? '1' : undefined"
      >add_photo_alternate</mat-icon
    >
  </button>
  <button
    *ngIf="node?.type?.name !== SFNodeType.part"
    mat-icon-button
    matTooltip="Delete"
    matTooltipPosition="right"
    (click)="deleteNode()"
  >
    <mat-icon class="negative">delete</mat-icon>
  </button>
</div>
