import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SfoUiJSONSchema7 } from '../../metadata.model';
import { RenderMode } from '../types/view.types';

@Component({
  selector: 'sfo-quicksettings-view',
  templateUrl: './quicksettings.component.html',
  styleUrls: ['./quicksettings.component.scss'],
  standalone: false,
})
export class QuickSettingsComponent {
  @Input() schema: SfoUiJSONSchema7;
  @Input() aFormControl: FormGroup;
  @Input() renderMode: RenderMode | undefined;

  get controlsInOrder(): string[] {
    return Object.keys(this.aFormControl.controls);
  }

  getFormControl(control: string): FormGroup {
    return this.aFormControl.get(control) as FormGroup;
  }
}
