import { Component, Input, OnDestroy, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateDocumentState } from 'editor';
import { getObjectDifferences } from '../../../../../ui-components/src';
import { BehaviorSubject, Subject, combineLatest, filter, map, take, takeUntil } from 'rxjs';
import * as ExportActions from '../../store/export.actions';
import { selectMetadata, selectTemplate } from '../../store/export.reducer';

@Component({
  selector: 'sfo-export-settings',
  templateUrl: './export-settings.component.html',
  styleUrls: ['./export-settings.component.scss'],
  standalone: false,
})
export class ExportSettingsComponent implements OnDestroy {
  private store: Store = inject(Store);

  private destroy$ = new Subject<void>();
  private selectedUi$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private templateMetadataKey: string = '';

  @Input() set selectedUi(value: string[]) {
    this.selectedUi$.next(value);
  }

  metaData$ = this.store.select(selectMetadata).pipe(
    take(1),
    map((metaData) => metaData),
    takeUntil(this.destroy$),
  );

  template$ = combineLatest([this.store.select(selectTemplate), this.selectedUi$]).pipe(
    filter(([template]) => !!template?.metaData),
    map(([template, uiRoleVisibility]) => this.filterConfigsByRole(template, uiRoleVisibility)),
    takeUntil(this.destroy$),
  );

  ngOnDestroy(): void {
    this.store.dispatch(ExportActions.minimizeExportSettings());
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateExportMetadata(form: object) {
    this.store
      .select(selectMetadata)
      .pipe(take(1))
      .subscribe((currentMetadata) => {
        const diff = getObjectDifferences(currentMetadata, form);

        if (!diff || Object.keys(diff).length === 0) {
          return;
        }

        this.store.dispatch(ExportActions.updateMetadata({ metaData: form }));
        this.store.dispatch(updateDocumentState({ dirty: true }));
      });
  }

  /**
   * Flattens the object at the specified key.
   * @param obj - The original object.
   * @param key - The key whose nested object should be flattened.
   * @returns The flattened object.
   */
  private flattenObjectByKey(obj: object, key: string): any {
    if (!obj || !obj.hasOwnProperty(key)) {
      return obj;
    }

    const flattenedObject = { ...obj };
    const nestedObject = flattenedObject[key];
    delete flattenedObject[key];

    return { ...flattenedObject, ...nestedObject };
  }

  private filterConfigsByRole(template: any, uiRoleVisibility: string[]) {
    const _template = structuredClone(template);
    const metadata = _template?.['metaData'];

    for (const [key, property] of Object.entries(metadata.properties)) {
      if (property?.['ui']) {
        const uiShowTo = property['ui']['showTo'];

        if (Array.isArray(uiShowTo) && !uiShowTo.some((role) => uiRoleVisibility.includes(role))) {
          delete metadata.properties[key];
        }
      }
    }
    return _template;
  }
}
