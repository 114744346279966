import { Component, Input, OnInit, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SfoUiJSONSchema7 } from '../../metadata.model';
import { FormService } from '../form.service';
import { RenderMode } from '../types/view.types';
import { SchemaKeywords, SfoUiWidgetType } from '../types';

@Component({
  selector: 'sfo-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss'],
  standalone: false,
})
export class DynamicFieldComponent implements OnInit {
  private formService: FormService = inject(FormService);
  private formBuilder: FormBuilder = inject(FormBuilder);

  @Input() schema: SfoUiJSONSchema7 | null | undefined;
  @Input() aFormControl: FormGroup | null;
  @Input() renderMode: RenderMode | undefined;
  @Input() control: string;

  SchemaKeywords = SchemaKeywords;
  SfoUiWidgetType = SfoUiWidgetType;

  ngOnInit(): void {}

  addRow(aFormArray: FormArray, schema: SfoUiJSONSchema7) {
    let newControl;

    if (schema.type === 'object') {
      newControl = this.formService.buildFormGroup(schema);
    } else {
      newControl = this.formBuilder.control(undefined); // or any default value
    }

    try {
      aFormArray.push(newControl);
    } catch (e) {
      console.error('The form array is not an instance of FormArray');
    }
  }

  removeRow(index: number, aFormArray: FormArray) {
    aFormArray.removeAt(index);
  }
}
