<div class="flex flex-column" style="height: 100%;">
  <h2 class="flex items-center mb0 px2 pt2">
    @switch (sfNodeType()) {
      @case ('part') {
        Chapter Settings
      }
      @default {
        Edit
      }
    }

    <div class="flex-auto"></div>

    @if (commands().length) {
      @for (command of commands(); track command.id) {
        <button
          mat-icon-button
          (click)="executeCommand(command)"
          [matTooltip]="command.tooltip"
          class="ml2"
          tabindex="-1"
        >
          <mat-icon [class.negative]="command.id === 'delete'">
            {{command.materialIconName}}
          </mat-icon>
        </button>
      }
    }

    @if (actions().length) {
      @for (action of actions(); track action) {
        <button
          *ngIf="action.type === 'button'"
          mat-icon-button
          [matTooltip]="action.tooltip || ''"
          (click)="executeAction(action)"
          tabindex="-1"
        >
          <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
          <span *ngIf="action.label">{{action.label}}</span>
        </button>

        <a
          *ngIf="action.type === 'link'"
          [href]="action.href"
          [target]="action.target || '_blank'"
          mat-icon-button
          [matTooltip]="action.tooltip || ''"
          tabindex="-1"
        >
          <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
          <span *ngIf="action.label">{{action.label}}</span>
        </a>
      }
    }
  </h2>

  <mat-dialog-content class="col-12">
    <sfo-dynamic-form
      [jsonSchema]="nodeMetaDataSchema()"
      [renderMode]="renderMode"
      [patchForm]="nodeMetaData()"
      [activeTab]="activeTab()"
      (formValue)="updateForm($event)"
    ></sfo-dynamic-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSave()">Save</button>
  </mat-dialog-actions>
</div>
