import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SfoUiJSONSchema7 } from '../../../../metadata.model';

@Component({
  selector: 'sfo-textarea',
  templateUrl: './textarea.component.html',
  standalone: false,
})
export class TextareaInputComponent implements OnInit {
  @Input() schema!: SfoUiJSONSchema7;
  @Input() aFormControl!: FormControl;
  @Input() defaultValue: unknown;

  placeholder: string;

  ngOnInit(): void {
    this.placeholder =
      typeof this.defaultValue === 'string'
        ? this.defaultValue
        : typeof this.schema?.default === 'string'
          ? this.schema.default
          : 'Enter text here';
  }
}
