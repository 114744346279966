<div *ngFor="let control of controlsInOrder">
  <ng-container
    *ngIf="schema.properties?.[control]?.type === 'object' ||schema.properties?.[control]?.type === 'array'"
  >
    <h2 class="h4 m0">
      {{ schema.properties?.[control]?.title ?? control | titlecase }}
    </h2>
    <small *ngIf="schema.properties?.[control]?.description"
      >{{schema.properties?.[control]?.description}}
    </small>
  </ng-container>
  <sfo-dynamic-field
    [schema]="schema.properties?.[control]"
    [aFormControl]="aFormControl"
    [renderMode]="'quickSettings'"
    [control]="control"
  ></sfo-dynamic-field>
</div>
