<mat-form-field floatLabel="always" appearance="fill" class="col-12" subscriptSizing="dynamic">
  <mat-label>{{ schema.title }}</mat-label>
  <input
    matInput
    type="number"
    [formControl]="aFormControl"
    [attr.min]="schema.minimum"
    [attr.max]="schema.maximum"
    placeholder="{{ defaultValue || schema.default }}"
  />
  <mat-hint *ngIf="schema.description">
    {{ schema.description }}
  </mat-hint>
  <mat-error *ngIf="aFormControl.hasError('min')">
    Value must be at least {{ schema.minimum }}.
  </mat-error>
  <mat-error *ngIf="aFormControl.hasError('max')">
    Value must be at most {{ schema.maximum }}.
  </mat-error>
</mat-form-field>
