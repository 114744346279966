import { Plugin, PluginKey, EditorState, TextSelection, NodeSelection } from 'prosemirror-state';
import { Fragment, Mark, Node, Slice } from 'prosemirror-model';
import { canSplit, insertPoint, ReplaceAroundStep } from 'prosemirror-transform';
import { createId, SFNodeType } from '@sciflow/schema';

export const fixProblems = (state) => {
    const tr = state.tr;
    const ids = new Map();
    const problems = [] as any;

    // check whether the document has a title
    if (tr.doc.firstChild?.type.name !== 'header') {
        problems.push({ problem: 'no-document-header' });
    }

    const schema = state.schema;

    /** Creates a fast deterministic hash (FNV-1a) */
    function fnv1aHash(str: string): string {
        let hash = 0x811c9dc5; // FNV offset basis
        for (let i = 0; i < str.length; i++) {
            hash ^= str.charCodeAt(i);
            hash = Math.imul(hash, 0x01000193); // FNV prime
        }
        return (hash >>> 0).toString(16); // Convert to unsigned hex
    }

    /** Generates a deterministic 12-character ID synchronously */
    function generateDeterministicId(node: Node): string {
        // Extract relevant data from the node
        const nodeData = JSON.stringify({
            type: node.type.name,
            attrs: node.attrs,
            content: node.content ? node.content.toJSON() : undefined
        });

        return fnv1aHash(nodeData).slice(0, 12);
    }

    /** Generates a unique ID synchronously */
    function getUniqueId(node: Node, parent: Node | null): string {
        const parentId = parent?.attrs?.id;
        let newId = generateDeterministicId(node);

        if (parentId) {
            newId = `${parentId.substr(0, 5)}-${newId}`;
        }

        while (ids.has(newId)) {
            const randomSuffix = fnv1aHash(newId + Math.random().toString()).slice(-2);
            newId = newId.slice(0, 10) + randomSuffix;
        }

        ids.set(newId, true);
        return newId;
    }

    /** mark an id as used */
    const markAsUsed = (id: string) => {
        if (!id) { return; }
        ids[id] = true;
    }
    const isUsed = (id: string) => { return ids[id]; }

    const hasId = (obj: any, isNode = true) => {
        let type;
        if (isNode) {
            type = schema.nodes[obj.type.name];
            if (!type) {
                throw new Error('Unknown node type ' + obj.type.name);
            }
        }
        else {
            type = schema.marks[obj.type.name];
            if (!type) {
                throw new Error('Unknown mark type ' + obj.type.name);
            }
        }
        return (type as any)?.attrs?.id != undefined;
    };


    tr.doc.descendants((node, pos, parent) => {

        if (node.type.name === SFNodeType.part) {
            if (node.childCount === 0) {
                problems.push({ problem: 'empty-part', pos, node, parent });
            }
        }

        const forcePart = localStorage.getItem('editor.forcePart');
        if (forcePart !== 'false') {

            // check if the text starts with a paragraph so we can create a free part
            let startWithFreePart = node === tr.doc.child(1) && tr.doc.child(0)?.type.name === SFNodeType.header && node.type.name === SFNodeType.paragraph;
            
            if (startWithFreePart || (node.type.name === SFNodeType.heading && parent?.type.name === SFNodeType.document && node.attrs?.level === 1)) {
                /**
                 * Ensure that all level-1 headings (`heading-1`) are wrapped inside a `part` node.
                 * This is achieved by scanning the document for standalone `heading-1` elements at the root level
                 * and identifying the range of content that belongs to each part by collecting all subsequent nodes
                 * until the next `heading-1` appears. Once the range is determined, the original nodes are deleted,
                 * and a new `part` node is created with the heading and its associated content as children.
                 * The transformation enforces a structured document where each `heading-1` serves as the start of a new section.
                 * TODO: identify sections without headings (like cover pages)
                 */
                let endPos = pos + node.nodeSize; // Start from heading position
                let stopScanning = false;
                tr.doc.nodesBetween(endPos, tr.doc.content.size, (nextNode, nextPos) => {
                    if (stopScanning) return false; // Stop processing further nodes

                    if (
                        nextNode.type.name === SFNodeType.heading && nextNode.attrs?.level === 1 ||
                        nextNode.type.name === SFNodeType.part
                    ) {
                        stopScanning = true; // Mark as found, so we stop further iterations
                        return false;
                    }

                    endPos = nextPos + nextNode.nodeSize;
                    return true;
                });
                problems.push({ problem: 'force-part', pos, node, parent, endPos });
            }
        }

        if (node.type.name === SFNodeType.heading && parent?.type.name === SFNodeType.part && node.attrs?.level === 1) {
            // make sure the heading is the first element
            if (parent?.firstChild !== node) {
                if (parent?.firstChild.textContent?.length > 0) {
                    // skip this for non-empty nodes
                    problems.push({ problem: 'heading-1-inside-part', pos, node, parent });
                }
            }
        }

        if (node.type.name === SFNodeType.figure) {
            // check that every figure has a caption
            const hasCaption = node.content.content?.some((n) => n.type.name === SFNodeType.caption);
        }

        if (node.type.name === SFNodeType.code && parent.type.name !== SFNodeType.figure) {
            // tables should be wrapped in figures
            problems.push({ problem: 'rogue-code-block', pos, node, parent });
        }


        if (node.type.spec.tableRole === 'table' && parent.type.name !== 'figure') {
            // tables should be wrapped in figures
            problems.push({ problem: 'rogue-table', pos, node });
        }
        if (node.type.spec.tableRole === 'cell' || node.type.spec.tableRole === 'header_cell') {
            if (node.childCount === 0) {
                problems.push({ problem: 'empty-cell', pos, node, parent });
            }
        }

        let { id, ...rest } = node.attrs;
        const marks = node.marks || [];

        const nodeHasIdAttr = typeof id !== 'undefined';
        const marksHaveIdAttr = marks.find((m: Mark) => m.attrs && m.attrs.id !== 'undefined');

        if (marksHaveIdAttr) {
            for (let mark of marks) {
                if (mark.attrs.id === null || isUsed(mark.attrs.id)) {
                    const newMark = schema.marks[mark.type.name].create({ ...mark.attrs, id: getUniqueId(node, parent) });
                    tr.removeMark(pos, pos + node.nodeSize, mark);
                    tr.addMark(pos, pos + node.nodeSize, newMark);
                } else if (mark.attrs.id?.length > 0) {
                    markAsUsed(mark.attrs.id);
                }
            }
        }

        if (nodeHasIdAttr) {
            if (id === null || isUsed(id)) {
                // get a new id for the node if needed
                id = getUniqueId(node, parent);
                tr.setNodeMarkup(pos, null, { id, ...rest }, marks.map(mark => {
                    if (mark.attrs.id === null) {
                        // @ts-ignore
                        mark.attrs.id = getUniqueId(node, parent);
                    }
                    return mark;
                }));
            } else {
                markAsUsed(node.attrs.id);
            }
        }

        /* // go through the document tree and set attributes for all nodes that have an id attr.
        if (node.attrs?.id === undefined) { return true; }

        if (node.attrs.id) { ids.set(node.attrs.id, node.type.name); }
        if (node.attrs?.id === null) {
            const id = getUniqueId();
            tr.setNodeMarkup(pos, null, { ...node.attrs, id }, node.marks);
        } */

        return true;
    });

    for (let { problem, pos, node, parent, endPos } of problems) {

        // since each tr changes the positions in the document we need to match the following ones to their new positioons
        pos = tr.mapping.map(pos);
        if (endPos != undefined) {
            endPos = tr.mapping.map(endPos);
        }

        switch (problem) {
            case 'no-document-header':
                if (state.schema.nodes.header) {
                    const header = state.schema.nodes.header.create({}, [state.schema.nodes.heading.create({}, [state.schema.text('Untitled document')])]);
                    tr.insert(0, [header]);
                }
                break;
            case 'rogue-table':
                rogue(tr, 'native-table', state, pos);
                break;
            case 'force-part':
                if (node.type.name === SFNodeType.heading || node.type.name === SFNodeType.paragraph) {
                    const isFreePart = node.type.name === SFNodeType.paragraph;
                    
                    const slice = tr.doc.slice(pos, endPos);
                    tr.delete(pos, endPos);
                    if (!node.attrs.type && node.attrs.role === 'abstract') {
                        node.attrs.type = 'abstract';
                    }
                    const partNode = schema.nodes[SFNodeType.part].create({
                        id: 'p-' + (node.attrs.id || createId()),
                        type: node.attrs.type ?? (isFreePart ? 'free' : undefined),
                        role: node.attrs.role,
                        numbering: node.attrs.numbering,
                        placement: node.attrs.placement
                    }, slice.content);
                    if (partNode.nodeSize > 2) {
                        tr.insert(pos, partNode);
                        tr.setSelection(TextSelection.create(tr.doc, pos + 2));
                    }
                }
                break;
            case 'rogue-code-block':
                rogue(tr, 'code', state, pos);
                break;
            case 'heading-1-inside-part':
                const partPos = tr.doc.resolve(pos).before();
                const slice = tr.doc.slice(partPos - 1, pos);
                tr.delete(partPos, pos);

                let newContent: Node[] = [];
                slice.content.forEach(node => {
                    if (node.nodeSize > 2) {
                        newContent.push(node);
                    }
                });
                tr.insert(partPos - 2, newContent);

                //tr.setSelection(TextSelection.create(tr.doc, partPos + 2));
                //tr.scrollIntoView();

                break;
            case 'empty-part':
            case 'empty-cell':
                const insert = insertPoint(tr.doc, pos + 1, state.schema.nodes.paragraph);
                if (insert) {
                    const fill = state.schema.nodes.paragraph.createAndFill({ id: createId() });
                    tr.insert(insert, [fill]);
                    tr.setMeta('FIX_PROBLEM', problem);
                } else {
                    console.error('could not find insertion point to add paragraph to empty cell', pos, insert, node, parent);
                }
                break;
        }
    }

    return tr.docChanged ? tr : null;
};

export const rogue = (tr, type, state, pos) => {
    const tableSelection: NodeSelection = new NodeSelection(tr.doc.resolve(pos));
    const figureType = state.schema.nodes.figure;
    const { $from, $to } = tableSelection;
    // create figure slice we can wrap the table into
    const figure = new Slice(Fragment.from([figureType.create({ type: type }, [state.schema.nodes.caption.create({}, [state.schema.nodes.paragraph.createAndFill({ id: createId() })])])]), 0, 0);
    tr.step(new ReplaceAroundStep(
        $from.pos, // replace from
        $to.pos, // replace to
        $from.pos, // start cutting gap at
        $to.pos, // end cutting gap
        figure, // use this slice as a wrapper
        1, // insertion point of the cut cap in the slice
        true)); // replace existing structure
};

export const integrityPluginKey = new PluginKey('integrity');

/*** Makes sure the document is valid. */
export const integrityPlugin = new Plugin({
    key: integrityPluginKey,
    props: {
        handlePaste(view, event, slice: Slice) {
            // TODO clean up Microsoft Word and other formats, styles, scripts
            console.log('Pasted HTML');

            // make sure the slice has fresh ids (otherwise they might duplicate those of a different part)
            slice.content.nodesBetween(0, slice.content.size - 2, (node, pos) => {
                if (node.attrs?.id !== undefined) {
                    // FIXME FE
                    // @ts-ignore
                    node.attrs.id = createId();
                }
                return true;
            });
            return false;
        },
        handleDrop(view, event, slice, moved) {
            if (!moved) {
                const schema = view.state.schema;
                // try to do an xref if needed
                // figure out what kind of element this belongs to
                if (slice.content?.firstChild?.marks.some((mark => mark.type.name === 'anchor'))) {
                    const label = slice.content?.firstChild.textContent;
                    const href = slice?.content?.firstChild?.marks.find((mark => mark.type.name === 'anchor'))?.attrs?.href;
                    const url = new URL(href);
                    const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });

                    if (!eventPos) { return false; }

                    // create a valid xref instead
                    const xref = schema.nodes.link.create({ type: 'xref', href: url.hash.replace('#', '') }, [schema.text(label)]);
                    const tr = view.state.tr;
                    const insertAt = insertPoint(tr.doc, eventPos.pos, schema.nodes.link);
                    if (!insertAt) { return false; }

                    console.log(xref.check(), xref, insertAt);

                    tr.insert(insertAt, xref);
                    tr.setSelection(TextSelection.create(tr.doc, insertAt, xref.nodeSize));
                    view.dispatch(tr);

                    return true;
                }
            }
            return false;
        }
    },
    appendTransaction(transactions, _oldState: EditorState, newState: EditorState) {
        if (!transactions.some((transaction) => transaction.docChanged)) { return null; }
        return fixProblems(newState);
    }
});
