import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { insertPoint } from 'prosemirror-transform';
import { setBlockType, toggleMark, wrapIn } from 'prosemirror-commands';
import { wrapInList, liftListItem, sinkListItem } from 'prosemirror-schema-list';
import { redo, undo } from 'prosemirror-history';

import { debounceTime, map } from 'rxjs/operators';
import { alignCommands, insertFigure, insertCode, insertMath, insertTable, tableCommands, getStructureCommands, insertPageBreak, insertFootnote } from './prosemirror/commands';

import { insertHyperlink } from './prosemirror/commands';

export interface Command { id?: string; materialIconName?: string; svgIcon?: string; tooltip: string; run: (state, dispatch) => any; active: boolean; }

export interface CommandGroup {
  id: string;
  label: string;
  priority: number;
  schema?: string;
  commands: Command[];
}

const activate = (state) => (command) => {
  return {
    id: command.id,
    materialIconName: command.materialIconName,
    svgIcon: command.svgIcon,
    tooltip: command.tooltip,
    active: command.select(state),
    select: command.select,
    run: command.run
  };
};

// Switching list types
export function liftList(state, dispatch) {
  const lift = liftListItem(state.schema.nodes.list_item);
  return lift(state, dispatch);
}

export function sinkList(state, dispatch) {
  const sink = sinkListItem(state.schema.nodes.list_item);
  return sink(state, dispatch);
}

export function toggleList(state, dispatch, listType) {
  const wrap = wrapInList(state.schema.nodes[listType]);
  return wrap(state, dispatch);
}

@Injectable()
export class MenuService {

  _menu$ = new Subject<CommandGroup[]>();
  _schema;

  set schema(schema) {
    this._schema = schema;
  }

  get menu$(): Observable<CommandGroup[]> {
    return this._menu$.pipe(map((v) => v), debounceTime(100));
  }

  constructor() { }

  update(state, schema, additionalCommands: CommandGroup[] = []): void {
    if (!this._schema) { return; }

    const bulletList = wrapInList(state.schema.nodes.bullet_list);
    const orderedList = wrapInList(state.schema.nodes.ordered_list);
    const BlockQuote = wrapIn(state.schema.nodes.blockquote);

    const matchSchema = item => !item.schema || item.schema === schema;

    const groups: CommandGroup[] = [
      {
        id: 'format',
        label: 'format',
        priority: 100,
        commands: [
          { id: 'strong', materialIconName: 'format_bold', tooltip: 'Strong', run: toggleMark(this._schema.marks.strong), select: toggleMark(this._schema.marks.strong) },
          { id: 'em', materialIconName: 'format_italic', tooltip: 'Emphasize', run: toggleMark(this._schema.marks.em), select: toggleMark(this._schema.marks.em) },
          { id: 'sub', svgIcon: 'sf-subscript', tooltip: 'Subscript', run: toggleMark(this._schema.marks.sub), select: toggleMark(this._schema.marks.sub) },
          { id: 'sup', svgIcon: 'sf-superscript', tooltip: 'Superscript', run: toggleMark(this._schema.marks.sup), select: toggleMark(this._schema.marks.sup) },
          { id: 'blockquote', schema: 'manuscript', svgIcon: 'blockquote', tooltip: 'Long quotation', run: BlockQuote, select: BlockQuote },
          { id: 'bullet_list', schema: 'manuscript', materialIconName: 'format_list_bulleted', tooltip: 'Bullet list', run: bulletList, select: (state) => insertPoint(state.doc, state.selection.from, state.schema.nodes.bullet_list) != null },
          { id: 'ordered_list', schema: 'manuscript', materialIconName: 'format_list_numbered', tooltip: 'Ordered list', run: orderedList, select: (state) => insertPoint(state.doc, state.selection.from, state.schema.nodes.ordered_list) != null },
          { id: 'lift_list_item', schema: 'manuscript', materialIconName: 'format_indent_decrease', tooltip: 'Decrease indent', run: liftListItem(state.schema.nodes.list_item), select: liftListItem(state.schema.nodes.list_item) },
          { id: 'sink_list_item', schema: 'manuscript', materialIconName: 'format_indent_increase', tooltip: 'Increase indent', run: sinkListItem(state.schema.nodes.list_item), select: sinkListItem(state.schema.nodes.list_item) },
          // { id: 'citation', schema: 'manuscript', svgIcon: 'cite', tooltip: 'cite', run: () => { throw new Error('Not implemented'); }, select: (state) => insertPoint(state.doc, state.selection.from, sciflowBaseSchema.nodes['citation']) != null },
          { id: 'code', schema: 'manuscript', materialIconName: 'code', tooltip: 'Code', run: insertCode(''), select: insertCode('') },
          { id: 'hyperlink', schema: 'manuscript', materialIconName: 'link', tooltip: 'Hyperlink', run: insertHyperlink(), select: insertHyperlink() },
          { id: 'figure', schema: 'manuscript', materialIconName: 'landscape', tooltip: 'figure', run: insertFigure(), select: insertFigure() },
          { id: 'table', schema: 'manuscript', materialIconName: 'border_all', tooltip: 'Table', run: insertTable(), select: insertTable() },
          { id: 'math', schema: 'manuscript', materialIconName: 'functions', tooltip: 'equation', run: insertMath(''), select: insertMath('') },
          { id: 'footnote', schema: 'manuscript', svgIcon: 'footnote', tooltip: 'footnote', run: insertFootnote(''), select: insertFootnote('') },
          { id: 'undo', materialIconName: 'undo', tooltip: 'Undo', run: undo, select: undo },
          { id: 'redo', materialIconName: 'redo', tooltip: 'Redo', run: redo, select: redo },
          { id: 'page-break', materialIconName: 'border_horizontal', tooltip: 'Insert a manual page break in the export (only after paragraphs)', run: insertPageBreak(), select: insertPageBreak() },
        ].map(activate(state))
      },
      {
        id: 'table',
        label: 'table',
        schema: 'manuscript',
        priority: 200,
        commands: [
          ...tableCommands,
          ...alignCommands
        ].filter(matchSchema).map(activate(state))
      },
      {
        id: 'elements',
        label: 'elements',
        schema: 'manuscript',
        priority: 300,
        commands: [
          { id: 'figure', materialIconName: 'landscape', run: insertFigure(), select: insertFigure() }
        ].filter(matchSchema).map(activate(state))
      },
      {
        id: 'structure',
        label: 'structure',
        schema: 'manuscript',
        priority: 400,
        commands: getStructureCommands(state.schema)
          .filter(matchSchema)
          .map(activate(state))
      },
      ...additionalCommands
    ].sort((a, b) => a.priority - b.priority);

    this._menu$.next(groups);
  }
}
