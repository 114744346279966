<div class="p1">
  <div class="flex flex-column pb1">
    <h2 class="h3 bold mb0" *ngIf="schema?.title">{{schema.title}}</h2>
    <small *ngIf="schema?.description">{{schema.description}}</small>
  </div>
  <mat-radio-group [formControl]="aFormControl" class="flex flex-column" style="gap: 0.5rem;">
    @if (schema.examples && schema.examples.length > 0) {
      <mat-radio-button
        *ngFor="let option of schema.examples"
        [value]="option?.['value'] === null ? null : option?.['value']"
        [checked]="option?.['value'] === aFormControl.value"
      >
        <span class="block" *ngIf="option?.['label']">{{ option?.['label'] }}</span>
        <small *ngIf="option?.['description']">{{ option?.['description'] }}</small>
      </mat-radio-button>
    } @else {
      <mat-radio-button
        *ngFor="let option of schema?.enum"
        [value]="option?.['value'] === null ? null : option?.['value']"
        [checked]="option?.['value'] === aFormControl.value"
      >
      </mat-radio-button>
    }
  </mat-radio-group>
</div>
