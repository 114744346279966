import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SfoUiJSONSchema7 } from '../../../../metadata.model';
import { SchemaKeywords } from '../../../types';

@Component({
  selector: 'sfo-enum-input',
  templateUrl: './enum-input.component.html',
  standalone: false,
})
export class EnumInputComponent implements OnInit {
  @Input() schema!: SfoUiJSONSchema7;
  @Input() aFormControl!: FormControl;
  @Input() defaultValue: unknown;

  SchemaKeywords = SchemaKeywords;

  ngOnInit(): void {
    const currentValue = this.aFormControl.value;

    if (this.schema?.examples) {
      if (Array.isArray(currentValue)) {
        const matchedExamples = currentValue
          .map((val) => this.schema.examples?.find((example) => example?.['value'] === val))
          .filter(Boolean);

        if (!matchedExamples.length) {
          // TODO: This is a workaround for the case when the value is an empty array
          const emptyExample = this.schema.examples?.find((example) => example?.['value'] === '');
          if (emptyExample) {
            this.aFormControl.setValue([emptyExample?.['value']], { emitEvent: false });
          } else {
            this.aFormControl.setValue(this.defaultValue, { emitEvent: false });
          }
        }
      } else if (typeof currentValue === 'string' || currentValue === null) {
        const matchedExample = this.schema.examples.find(
          (example) => example?.['value'] === currentValue,
        );

        if (!matchedExample) {
          this.aFormControl.setValue(this.defaultValue, { emitEvent: false });
        }
      }
    } else if (this.schema?.enum) {
      const matchedEnum = this.schema.enum.find((enumValue) => enumValue === currentValue);

      if (!matchedEnum) {
        this.aFormControl.setValue(this.defaultValue, { emitEvent: false });
      }
    }
  }

  onSelectionChange(event: any): void {
    if (this.schema[this.SchemaKeywords.Multiple] !== true) {
      return;
    }

    const selectedValues = event.value;
    if (selectedValues && selectedValues.includes(null)) {
      this.aFormControl.setValue([null]);
    }
  }
}
