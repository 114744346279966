// TODO (@sciflow/schema): SFNodeType could be imported due to TypeScript wasn't resolving this well.
// import { SFNodeType } from '@sciflow/schema';
// import { Command } from 'editor';
import { Component, Inject, model } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { BehaviorSubject } from 'rxjs';
import { RenderMode } from '../../form-builder';
import { Command } from '../types';

export interface DialogAction {
  id?: string;
  label?: string;
  icon?: string;
  tooltip?: string;
  type: 'button' | 'link';

  /**
   * Callback function to be executed when the action is triggered (only for buttons).
   */

  onClick?: () => void;

  href?: string;
  target?: string;
}

/**
 * Represents metadata for a node in a document editor.
 * This interface provides essential information about a node's structure, schema, and rendering context.
 */
export interface NodeMetaEditorDialogData {
  /** The actual node instance being represented */
  node: Node;

  /**
   * Schema configuration for the node.
   * Defines the structure and attributes that can be applied to the node.
   */
  schema: {
    [key: string]: any;
  };

  /**
   * Specifies how the node should be rendered in the editor.
   * Controls the visual representation and behavior of the node.
   */
  renderMode: RenderMode;

  /**
   * Specifies which tab should be initially active when using 'tabbed' renderMode.
   * Can be either a tab index (number) or tab name (string).
   */
  activeTab?: string | number;

  /**
   * Optional array of commands that can be executed on this node.
   */
  commands?: Command[];

  /**
   * Provides access to the editor's state and dispatch functions.
   */
  editorView?: EditorView;

  /**
   * Optional array of custom actions (buttons/links) for this node.
   */
  actions?: DialogAction[];
}

@Component({
  selector: 'sfo-node-meta-editor-dialog',
  templateUrl: './node-meta-editor-dialog.component.html',
  styleUrls: ['./node-meta-editor-dialog.component.scss'],
  standalone: false,
})
export class NodeMetaEditorDialog {
  readonly SFNodeType: any;

  readonly renderMode: RenderMode = this.data.renderMode as RenderMode;
  readonly nodeMetaData = model<object>(this.data.node?.attrs);
  readonly nodeMetaDataSchema = model(this.data.schema);
  readonly sfNodeType = model<any>(this.data.node?.type?.name);
  readonly commands = model<Command[]>(this.data.commands || []);
  readonly actions = model<DialogAction[]>(this.data.actions || []);
  readonly editorView = model<EditorView | undefined>(this.data.editorView);
  readonly activeTab = model<string | number | undefined>(this.data.activeTab);

  private form$ = new BehaviorSubject<object | undefined>(undefined);

  constructor(
    private dialogRef: MatDialogRef<NodeMetaEditorDialog>,
    @Inject(MAT_DIALOG_DATA) readonly data: NodeMetaEditorDialogData,
  ) {}

  executeCommand(command: Command): void {
    const view = this.editorView();
    if (command.run && view) {
      command.run(view.state, view.dispatch);
      this.dialogRef.close(null);
    }
  }

  executeAction(action: DialogAction): void {
    if (action.type === 'button' && action.onClick) {
      action.onClick();
      this.dialogRef.close(null);
    }
  }

  updateForm(values) {
    this.form$.next(values);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSave(): void {
    let formValues = this.form$.getValue();

    if (!formValues) {
      this.dialogRef.close(null);
      return;
    }

    formValues = this.setMissingKeysToUndefined(formValues, this.data.node?.attrs);

    this.dialogRef.close(formValues);
  }

  /**
   * Helping function to set any missing values to undefined. Form values not returned due to sanitising of falsy values.
   * @param formValues
   * @param originalData
   * @returns
   */
  private setMissingKeysToUndefined(formValues: any, originalData: any): any {
    if (!formValues || !originalData) {
      return formValues;
    }

    const result = { ...formValues };

    Object.keys(originalData).forEach((key) => {
      if (!(key in result)) {
        result[key] = undefined;
      }
    });

    return result;
  }
}
