import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ProsemirrorModule } from '../prosemirror/prosemirror.module';

import { MatBadgeModule } from '@angular/material/badge';
import { SfoDynamicFormModule } from 'projects/ui-components/src';
import { PipesModule } from '../pipes/pipes.module';
import { CiteComponent } from './cite/cite.component';
import { FigureMenuComponent } from './figure/figure-menu/figure-menu.component';
import { FigurePreviewModalComponent } from './figure/figure-preview-modal/figure-preview-modal.component';
import { FigureComponent } from './figure/figure.component';
import { FootnoteComponent } from './footnote/footnote.component';
import { HyperlinkDialogComponent } from './hyperlink-dialog/hyperlink-dialog.component';
import { InfoComponent } from './info/info.component';
import { MathEditorComponent } from './math/math-editor/math-editor.component';
import { PmNodeMenuComponent } from './node-menu/node-menu.component';
import { SCHEMA_PROVIDER, StoreSchemaProvider } from './node-menu/schema-provider.interface';

@NgModule({
  declarations: [
    FootnoteComponent,
    FigureComponent,
    InfoComponent,
    HyperlinkDialogComponent,
    FigureMenuComponent,
    FigurePreviewModalComponent,
    MathEditorComponent,
    CiteComponent,
    PmNodeMenuComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatDialogModule,
    ProsemirrorModule,
    SfoDynamicFormModule,
  ],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: SCHEMA_PROVIDER, useClass: StoreSchemaProvider },
  ],
})
export class TextElementsModule {
  constructor(injector: Injector) {
    const FigureMenuComponentElement = createCustomElement(FigureMenuComponent, { injector });
    customElements.define('sf-figure-menu-element', FigureMenuComponentElement);

    const PmNodeMenuElement = createCustomElement(PmNodeMenuComponent, { injector });
    customElements.define('pm-node-menu', PmNodeMenuElement);
  }
}
