<div *ngIf="prosemirrorNodeSchema" class="sf-part-wrapper">
  <button
    mat-stroked-button
    (click)="openDialog()"
    matTooltip="Edit chapter"
    contenteditable="false"
  >
    {{node.attrs.type | titlecase}}
  </button>
</div>
