<div class="menu" *ngIf="data.node?.attrs.role !== 'title'">
  <div class="flex flex-column">
    <div class="flex flex-row justify-center items-center">
      <button
        *ngIf="data.type !== 'part'"
        class="flex-auto label"
        color="none"
        mat-button
        matTooltip="change {{data.node.type.name}} {{ data.node?.attrs.level }}"
        [matMenuTriggerFor]="headingMenu"
        matTooltipPosition="before"
        [ngSwitch]="data.node.type.name"
      >
        <b *ngSwitchCase="'heading'"><mat-icon [matBadge]="data.node?.attrs.level"  matBadgePosition="below">short_text</mat-icon></b>
        <b *ngSwitchCase="'paragraph'" class="m1"><mat-icon>short_text</mat-icon></b>
        <b *ngSwitchDefault>
          {{ data.node?.type?.name }}
        </b>
      </button>
    </div>
  </div>

  <mat-menu #headingMenu="matMenu" yPosition="above">
    <button
      mat-menu-item
      [disabled]="data.node.type.name === 'paragraph'"
      (click)="setNodeType('paragraph')"
      [ngClass]="{ selected: data.node.type.name === 'paragraph' }"
    >
      <mat-icon>short_text</mat-icon>
      <span>Paragraph</span>
    </button>
    <button
      *ngFor="let heading of headings"
      [disabled]="data.node.attrs.level === heading.level"
      mat-menu-item
      (click)="setHeadingLevel(heading.level)"
      class="button"
      [ngClass]="{ selected: (data.node.attrs.level === heading.level) }"
    >
      <small>{{ '#'.repeat(heading.level) }} </small><span>Heading {{heading.level}}</span>
    </button>
  </mat-menu>
</div>
