/**
 * Defines keywords used in schema definitions to control UI behavior and presentation.
 * These keywords extend standard JSON Schema with UI-specific metadata.
 */
export enum SchemaKeywords {
  UI = 'ui',
  UiIcon = '_ui_icon',

  /**
   * Defines the specific widget type to render for a schema property.
   * Used to override the default input component (based on `type`) for a JSON schema property.
   * @see {@link SfoUiWidgetType} For available widget options
   * @example
   * '_ui_widget': 'sfo-rich-text'
   */
  UiWidget = '_ui_widget',

  /**
   * Tags a schema property for grouping or categorization.
   * Used by filtering and organization features.
   * @example
   * '_ui_tag': 'advanced'
   */
  UiTag = '_ui_tag',

  /**
   * Provides instructional content or contextual help text about a schema section.
   *
   * When present, this content is displayed in an {@link SfoAdmonitionComponent}
   * to help users understand the purpose of form fields or sections.
   *
   * Note: Currently only rendered in {@link SfoDrawerViewComponent} layouts.
   *
   * @example
   * // Basic usage
   * '_readme': 'This section controls page formatting options.'
   *
   * @example
   * // With markdown formatting
   * '_readme': '## Layout Settings\n\nThese options affect how content is arranged on the page.'
   *
   * @see {@link SfoAdmonitionComponent} For the component that renders this content
   * @see {@link SfoDrawerViewComponent} For the layout component that supports readme displays
   */
  Readme = '_readme',

  /**
   * Groups related form fields together for visual organization.
   * Fields with matching group values appear together in the UI.
   * (Only supported in Tab View)
   * @example
   * '_ui_group': 'Layout'
   */
  UiGroup = '_ui_group',

  /**
   * When true, hides the field from the rendered form.
   * Field remains in the form model but is not visible to users.
   * @example
   * '_ui_hide': true
   */
  UiHide = '_ui_hide',

  /**
   * Indicates whether the field can return null values.
   * @example
   * '_nullable': true
   */
  Nullable = '_nullable',

  /** Specifies a component to use for rendering */
  Component = 'component',

  /**
   * Allows multiple values to be selected for a dropdown.
   */
  Multiple = '_multiple',

  /**
   * Specifies the width of the table column using basscss classes.
   */
  UiTableWidth = '_ui_table_width',

  /**
   * Specifies the class to apply to a UI element.
   */
  UiClass = '_ui_class',
}

/**
 * Defines the available specialized input widgets that can be assigned
 * to form fields through the UiWidget schema keyword.
 */
export enum SfoUiWidgetType {
  /** Date input field with date picker */
  DateInput = 'sfo-date-input',

  /** Code editor with syntax highlighting */
  CodeEditor = 'sfo-code-editor',

  /** Rich text editor with formatting options */
  RichText = 'sfo-rich-text',

  /** Multi-line text area input */
  Textarea = 'sfo-textarea',

  /** Radio button group for selecting from predefined options */
  RadioGroup = 'sfo-radio-group',

  /** Dropdown selection menu */
  EnumInput = 'sfo-enum-input',
}

// TODO: https://gitlab.com/sciflow/ee/development/-/issues/1019
